var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "rtl" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-column white" },
      [
        _c(
          "div",
          { staticClass: "position-relative mx-auto" },
          [
            _c(
              "croppa",
              {
                staticClass: "text-center mt-2",
                attrs: {
                  width: 150,
                  height: 150,
                  quality: 4,
                  "prevent-white-space": true,
                  placeholder: "",
                  "canvas-color": "transparent",
                  "show-remove-button": true,
                  "remove-button-color": "red",
                  "remove-button-size": 24,
                },
                on: {
                  init: _vm.onInit,
                  "image-remove": _vm.handleNewImage,
                  "file-choose": _vm.handleNewImage,
                },
                model: {
                  value: _vm.profilePic,
                  callback: function ($$v) {
                    _vm.profilePic = $$v
                  },
                  expression: "profilePic",
                },
              },
              [
                _vm.user.profilePicture
                  ? _c("img", {
                      attrs: {
                        slot: "initial",
                        crossorigin: "anonymous",
                        src: _vm.getProfilePic,
                      },
                      slot: "initial",
                    })
                  : _vm._e(),
                _c("img", {
                  attrs: {
                    slot: "placeholder",
                    src: require("@/assets/images/profile-circle.png"),
                  },
                  slot: "placeholder",
                }),
              ]
            ),
            _c(
              "v-icon",
              {
                staticClass:
                  "avatar-select position-absolute rounded-pill primary white--text text-center p-2",
                attrs: { small: "" },
                on: {
                  click: function ($event) {
                    return _vm.profilePic.chooseFile()
                  },
                },
              },
              [_vm._v(" mdi-pencil ")]
            ),
          ],
          1
        ),
        _c(
          "v-form",
          {
            ref: "form",
            staticClass: "d-flex flex-column mt-2",
            attrs: { "lazy-validation": "" },
            model: {
              value: _vm.valid,
              callback: function ($$v) {
                _vm.valid = $$v
              },
              expression: "valid",
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column justify-content-between mt-auto",
              },
              [
                _c("v-text-field", {
                  staticClass: "text-center ms-0 me-1",
                  attrs: {
                    outlined: "",
                    type: "text",
                    label: _vm.$t("Enter your name"),
                    rules: [(v) => !!v],
                    required: "",
                  },
                  model: {
                    value: _vm.firstName,
                    callback: function ($$v) {
                      _vm.firstName = $$v
                    },
                    expression: "firstName",
                  },
                }),
                _c("v-text-field", {
                  staticClass: "text-center me-0 ms-1",
                  attrs: {
                    outlined: "",
                    type: "text",
                    label: _vm.$t("Enter your last name"),
                    rules: [(v) => !!v],
                    required: "",
                  },
                  model: {
                    value: _vm.lastName,
                    callback: function ($$v) {
                      _vm.lastName = $$v
                    },
                    expression: "lastName",
                  },
                }),
                _c("v-text-field", {
                  staticClass: "text-center mt-1",
                  attrs: {
                    outlined: "",
                    type: "number",
                    label: _vm.$t("Enter your age"),
                    rules: [(v) => !!v],
                    required: "",
                  },
                  model: {
                    value: _vm.age,
                    callback: function ($$v) {
                      _vm.age = $$v
                    },
                    expression: "age",
                  },
                }),
                _c("v-autocomplete", {
                  attrs: {
                    label: _vm.$t("Choose your city"),
                    items: _vm.cities,
                    "item-text": "name",
                    "item-value": "name",
                    outlined: "",
                    required: "",
                    "small-chips": "",
                    "no-data-text": _vm.$t("No data"),
                    rules: [(v) => !!v],
                  },
                  model: {
                    value: _vm.chooseCity,
                    callback: function ($$v) {
                      _vm.chooseCity = $$v
                    },
                    expression: "chooseCity",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: _vm.$t("Choose your gender"),
                    outlined: "",
                    required: "",
                    "small-chips": "",
                    "no-data-text": _vm.$t("No data"),
                    items: _vm.genderItems,
                    "item-text": "text",
                    "item-value": "value",
                    rules: [(v) => !!v],
                  },
                  model: {
                    value: _vm.gender,
                    callback: function ($$v) {
                      _vm.gender = $$v
                    },
                    expression: "gender",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: _vm.$t("Choose your marriage status"),
                    outlined: "",
                    required: "",
                    "small-chips": "",
                    "no-data-text": _vm.$t("No data"),
                    items: _vm.marriageList,
                    "item-text": "text",
                    "item-value": "value",
                    rules: [(v) => !!v],
                  },
                  model: {
                    value: _vm.marriageStatus,
                    callback: function ($$v) {
                      _vm.marriageStatus = $$v
                    },
                    expression: "marriageStatus",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: _vm.$t("Choose your education"),
                    outlined: "",
                    required: "",
                    "small-chips": "",
                    "no-data-text": _vm.$t("No data"),
                    items: _vm.educationList,
                    "item-text": "text",
                    "item-value": "value",
                    rules: [(v) => !!v],
                  },
                  model: {
                    value: _vm.educationType,
                    callback: function ($$v) {
                      _vm.educationType = $$v
                    },
                    expression: "educationType",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "mt-4 mb-2 text-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "col-12",
                        attrs: {
                          color: "primary",
                          large: "",
                          loading: _vm.submit_loading,
                          disabled: _vm.submit_loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.validate()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Save changes")) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }