<template>
  <section class="rtl">
    <div class="d-flex flex-column white">

        <div class="position-relative mx-auto">
          <croppa 
            class="text-center mt-2" 
            v-model="profilePic" 
            :width="150"
            :height="150"
            :quality="4"
            :prevent-white-space="true" 
            @init="onInit" 
            placeholder="" 
            canvas-color="transparent"
            @image-remove="handleNewImage" 
            @file-choose="handleNewImage" 
            :show-remove-button="true"
            remove-button-color="red"
            :remove-button-size="24"
             >
            
            <img slot="initial" crossorigin="anonymous" v-if="user.profilePicture" :src="getProfilePic" />
            <img slot="placeholder" src="@/assets/images/profile-circle.png"/>
            
          </croppa>
          <v-icon small class="avatar-select position-absolute rounded-pill primary white--text text-center p-2" @click="profilePic.chooseFile()">
            mdi-pencil 
          </v-icon>
        </div>

        <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column mt-2">
          <div class="d-flex flex-column justify-content-between mt-auto">

            <v-text-field outlined v-model="firstName" type="text" :label="$t('Enter your name')"
                class="text-center ms-0 me-1" :rules="[(v) => !!v]" required />

            <v-text-field outlined v-model="lastName" type="text" :label="$t('Enter your last name')"
                class="text-center me-0 ms-1" :rules="[(v) => !!v]" required />

            <v-text-field outlined v-model="age" type="number" :label="$t('Enter your age')"
                  class="text-center mt-1" :rules="[(v) => !!v]" required />

            <v-autocomplete :label="$t('Choose your city')" v-model="chooseCity" :items="cities" item-text="name"
              item-value="name" outlined required small-chips :no-data-text="$t('No data')" :rules="[(v) => !!v]">
            </v-autocomplete>

            <v-select :label="$t('Choose your gender')" v-model="gender" outlined required small-chips
              :no-data-text="$t('No data')" :items="genderItems" item-text="text" item-value="value"
              :rules="[(v) => !!v]">
            </v-select>

            <v-select :label="$t('Choose your marriage status')" v-model="marriageStatus" outlined required small-chips
              :no-data-text="$t('No data')" :items="marriageList" item-text="text" item-value="value"
              :rules="[(v) => !!v]">
            </v-select>
            
            <v-select :label="$t('Choose your education')" v-model="educationType" outlined required small-chips
              :no-data-text="$t('No data')" :items="educationList" item-text="text" item-value="value"
              :rules="[(v) => !!v]">
            </v-select>

            <div class="mt-4 mb-2 text-center">
              <v-btn color="primary" large class="col-12" @click="validate()" :loading="submit_loading" :disabled="submit_loading">
                {{$t('Save changes')}}
              </v-btn>
            </div>
          </div>
      </v-form>
    </div>
  </section>
</template>
<script>
import cityDATA from "@/assets/city.json";
import apiService from "@/http/ApiService.js"

export default {
  data() {
    return {
      submit_loading: false,
      
      valid: false,
      progress: 0,
      
      isLoadingEducation: true,
      isLoadingSpecialty: true,
      isLoadingActivity: true,

      picChanged: false,
      uploadedPicUrl: "",
      uploadedThumbnailPicUrl: "",
      profilePic: {},

      cities: cityDATA,
      firstName: "",
      lastName: "",
      username: "",
      mobileNumber: this.$store.getters.mobileNumber,
      age: "",
      gender: "",
      chooseCity: "",
      educationType: "",
      marriageStatus: "",
      
      marriageList: [
        { text: this.$t("Single"), value: "SINGLE" },
        { text: this.$t("Married"), value: "MARRIED" }
      ],
      genderItems: [
        { text: this.$t("Man"), value: "MAN" },
        { text: this.$t("Woman"), value: "WOMAN" },
        { text: this.$t("Other"), value: "OTHER" },
      ],
      educationList: [
        { text: this.$t("DIPLOMA"), value: "DIPLOMA" },
        { text: this.$t("TECHNIQUE"), value: "TECHNIQUE" },
        { text: this.$t("BACHELOR"), value: "BACHELOR" },
        { text: this.$t("MA"), value: "MA" },
        { text: this.$t("RESEARCH"), value: "RESEARCH" },
        { text: this.$t("OTHER"), value: "OTHER" }
      ],

      user: {
        profilePicture: ''
      },

    };
  },
  computed:{
    getProfilePic() {
      return this.user.profilePicture.replace("http://", "https://")
    }
  },
  methods: {
    rand(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    onInit() {
      this.profilePic.addClipPlugin(function (ctx, x, y, w, h) {
        /*
         * ctx: canvas context
         * x: start point (top-left corner) x coordination
         * y: start point (top-left corner) y coordination
         * w: croppa width
         * h: croppa height
        */
        ctx.beginPath()
        ctx.arc(x + w / 2, y + h / 2, w / 2, 0, 2 * Math.PI, true)
        ctx.closePath()
      })
    },
    handleNewImage() {
      this.picChanged = true;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.UpdateUser();
      }
    },
    getUserInfoByMyMobile() {
      apiService.getUserInformationByPhoneNumber(this.$store.getters.mobileNumber)
        .then((response) => {
          this.user = response.data;
          // this.profilePic = this.user.profilePicture;
          this.firstName = this.user.firstName;
          this.lastName = this.user.lastName;
          this.mobileNumber = this.user.mobileNumber;
          this.chooseCity = this.user.livingCity;
          this.gender = this.user.gender;
          this.educationType = this.user.educationType;
          this.marriageStatus = this.user.marriageStatus;
          this.age = this.user.age;
          
          this.profilePic.refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    UpdateUser() {
      if (this.picChanged) {
        this.uploadPic();
      }
      else {
        this.submitForm();
      }
    },
    submitForm() {
      this.submit_loading = true;

      var ClientUserDTO = {
        id: this.user.id,
        deleted: this.user.deleted,
        version: this.user.version,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.user.email,
        mobileNumber: this.mobileNumber,
        gender: this.gender,
        marriageStatus: this.marriageStatus,
        educationType: this.educationType,
        livingCity: this.chooseCity,
        age: this.age,
        notificationToken: this.user.notificationToken,
        profilePicture: this.picChanged ? this.uploadedPicUrl : this.user.profilePicture, //TODO
        thumbnailUrl: this.picChanged ? this.uploadedThumbnailPicUrl : this.user.thumbnailUrl, //TODO
        enabled: this.user.enabled,
        roleCode: this.user.roleCode,
        freeCount: this.user.freeCount
      };

      apiService.UpdateClientUserDTO(ClientUserDTO)
        .then((response) => {
          this.submit_loading = false;
          if (response.status == 200) {
            this.$router.push("/c/profile");
          }
        })
        .catch((err) => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    },
    uploadPic() {
      this.submit_loading = true;
      if (this.profilePic.hasImage()) {
        this.profilePic.generateBlob((blob) => {
            apiService.upload("PROFILE", blob, Date.now() + ".png", (event) => {
              this.progress = Math.round((100 * event.loaded) / event.total);
            })
              .then((response) => {
                this.submit_loading = false;
                this.uploadedPicUrl = response.data.originalFileDownloadUrl;
                this.uploadedThumbnailPicUrl = response.data.thumbnailPicDownloadUrl;
                this.submitForm();
              })
              .catch(() => {
                this.submit_loading = false;
                this.$swal({
                  icon: "warning",
                  text: this.$t('Could not upload the file!'),
                  confirmButtonText: this.$t("OK"),
                });
              });
          },
          'image/png',
          1
        ); // 85% compressed jpeg file
      }
      else {
        this.$swal({
          icon: "warning",
          text: this.$t('No image selected. Please select an image'),
          confirmButtonText: this.$t("OK"),
        });
        this.submit_loading = false;
      }
    },
  },
  created() {
    this.getUserInfoByMyMobile();
  },
}
</script>
<style scoped>
.croppa-container {
  background-color: transparent !important;
}
</style>
